import React from 'react';
import styled from 'styled-components';

import { PostLink } from '@t/components';
import { InlineBlockLink } from '@/components/common/Link';
import createGA from '@/components/common/createGA';

interface PostMetaInfoProps {
  author: string;
  date: string;
  btnLabel: string;
  btnLink: PostLink;
}

const ItemInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 20px;
  color: #777;
  font-size: 14px;

  @media (max-width: 720px) {
    max-width: 640px;
    font-size: 10px;
  }
`;

const MetaInfo = styled.div`
  display: flex;

  & span:first-child:after {
    display: inline-block;
    content: '';
    height: 11px;
    border-left: 1px solid #777;
    margin: 0 8px;
  }

  @media (max-width: 720px) {
    flex-direction: row-reverse;

    & span:first-child:after {
      display: none;
    }

    & span:last-child:after {
      display: inline-block;
      content: '';
      height: 8px;
      border-left: 1px solid #777;
      margin: 0 8px;
    }
  }
`;

const Info = styled.span`
  @media (max-width: 720px) {
    font-size: 14px;
  }
`;

const MoreLink = styled.span`
  display: inline-block;
  color: #515ce6;
  padding: 0 16px;

  @media (max-width: 720px) {
    display: none;
  }
`;

const IconMoreArrow = styled.i`
  position: absolute;
  top: 7px;
  right: 3px;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.09em solid #515ce6;
  border-top: 0.09em solid #515ce6;
  transform: rotate(45deg);
  font-style: normal;
`;

const PostMetaInfo: React.FC<PostMetaInfoProps> = ({ author, date, btnLabel, btnLink }) => {
  const ga = createGA('Post - detail', 'click', 'button', 'to list button');

  return (
    <ItemInfo>
      <MetaInfo>
        <Info>{author}</Info>
        <Info>{date}</Info>
      </MetaInfo>
      <InlineBlockLink type="internal" url={btnLink} ga={ga}>
        <MoreLink>
          {btnLabel}
          <IconMoreArrow />
        </MoreLink>
      </InlineBlockLink>
    </ItemInfo>
  );
};

export default PostMetaInfo;
