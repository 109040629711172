import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import { Post, ProductInfo } from '@t/components';
import { createProductSectionData, replaceInvalidHtml } from '@/util';
import { postUrl } from '@/constant';
import SEO from '@/components/SEO';
import PostTags from '@/components/post/PostTags';
import PostMetaInfo from '@/components/post/PostMetaInfo';
import FloatingTopBtn from '@/components/FloatingTopBtn';
import SitemapSection from '@/components/main/sitemap/SitemapSection';
import createGA from '@/components/common/createGA';
import PostContent from '@/components/detail/PostContent';

import '@/query/postQueryFragment';

interface PostProps {
  data: {
    post: {
      frontmatter: Post;
      html: string;
    };
    allProductsJson: { edges: { node: ProductInfo }[] };
  };
  pageContext: {
    lang: 'ko' | 'en';
  };
  location: {
    host: string;
    pathname: string;
  };
}

const Contents = styled.div`
  margin-top: 69px;
  border-bottom: 1px solid #e5e5e5;

  @media (max-width: 720px) {
    margin-top: 56px;
    border-bottom: none;
  }
`;

const ContentHTML = styled.div`
  position: relative;
  padding: 60px 0 100px;
  color: #222;
  line-height: 1.5;
  font-size: 16px;

  @media (max-width: 720px) {
    padding: 25px 0;
  }
`;

const ContentContainer = styled.div`
  max-width: 1060px;
  margin: 0 auto;
  border-bottom: 1px solid #e5e5e5;

  @media (max-width: 1200px) {
    padding: 0 12.5%;
  }

  @media (max-width: 720px) {
    max-width: 640px;
    padding: 0;
    width: 89%;
  }
`;

const Content = styled(PostContent)`
  padding-bottom: 60px;

  @media (max-width: 720px) {
    padding-bottom: 50px;
  }
`;

const PostMetaInfoArea = styled.div`
  max-width: 1060px;
  margin: 0 auto;
  padding-top: 40px;

  @media (max-width: 1200px) {
    padding: 40px 12.5% 0;
  }

  @media (max-width: 720px) {
    max-width: 640px;
    width: 89%;
    margin: 0 auto;
    padding: 0;
    padding-top: 20px;
  }
`;

const MobileSitemapWrapper = styled.div`
  @media (max-width: 720px) {
    display: none;
  }
`;

const PostDetail: React.FC<PostProps> = ({ data, pageContext, location }) => {
  const { allProductsJson, post } = data;
  const { frontmatter, html } = post;
  const { lang } = pageContext;
  const { title, description, author, date, tags, thumbnail } = frontmatter;

  const replacedHtml = replaceInvalidHtml(html, true);
  const url = `${location.host}${location.pathname}`;

  const sitemapData = createProductSectionData(allProductsJson);
  const ga = createGA('Posts - sitemap', 'click', 'sitemap');

  const content = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line no-undefined
  const image = thumbnail ?? undefined;

  return (
    <>
      <Contents>
        <SEO title={title} description={description} url={url} image={image} />
        <ContentHTML ref={content}>
          <ContentContainer>
            <Content dangerouslySetInnerHTML={{ __html: replacedHtml }} />
          </ContentContainer>
          <PostMetaInfoArea>
            <PostTags tags={tags} lang={lang} />
            <PostMetaInfo
              author={author}
              date={date}
              btnLabel="Back to list"
              btnLink={postUrl[lang.toUpperCase() as 'KO' | 'EN']}
            />
          </PostMetaInfoArea>
          <FloatingTopBtn content={content} />
        </ContentHTML>
      </Contents>
      <MobileSitemapWrapper>
        <SitemapSection items={sitemapData} ga={ga} />
      </MobileSitemapWrapper>
    </>
  );
};

export const pageQuery = graphql`
  query ($id: String!) {
    post: markdownRemark(frontmatter: { id: { eq: $id } }) {
      html
      ...PostFragment
    }

    # query for each section
    ...allProductsJson
  }
`;

export default PostDetail;
